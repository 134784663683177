body .design-class {
  line-height: var(--paragraph-line-height);
  font-weight: var(--paragraph-font-weight);
  color: var(--text-color-6);
  font-family: var(--paragraph-font-family);
  font-size: var(--paragraph-font-size);
}

.design-class .heading-l100 {
  font-weight: calc(var(--heading-font-weight) - 100);
  /*Theo thiết lập độ đậm - 100*/
}

.design-class .heading-b100 {
  font-weight: calc(var(--heading-font-weight) + 100);
  /*Theo thiết lập độ đậm + 100*/
}

.design-class .body-l100 {
  font-weight: calc(var(--paragraph-font-weight) - 100);
  /*Theo thiết lập độ đậm - 100*/
}

.design-class .body-b100 {
  font-weight: calc(var(--paragraph-font-weight) + 100);
  /*Theo thiết lập độ đậm + 100*/
}

.design-class blockquote,
.design-class ol,
.design-class p,
.design-class ul {
  margin-bottom: round(calc(var(--paragraph-font-size) * var(--paragraph-line-height)));
  /*round(Kích thước base font-size * Chiều cao dòng) = round(13 * 1.5) = 19.5px*/
}

.design-class .small,
.design-class small {
  font-size: var(--paragraph-font-size);
  /*Tính theo body base font-size / Tỷ lệ = 1/scale rem*/
  /*line-height, color, font-weight, font-family: inherit từ body*/
}

.design-class .lead-1 {
  font-weight: var(--paragraph-font-weight);
  font-size: calc(var(--paragraph-font-size) * var(--paragraph-font-ratio) * var(--paragraph-font-ratio));
  /*Kích thước Base font-size * Tỷ lệ ^ (3 - Lead Level). Vd: Lead 2 = 13 * 1.25 ^ (3 - 2) = 16.25 */
}

.design-class .lead-2 {
  font-weight: var(--paragraph-font-weight);
  font-size: calc(var(--paragraph-font-size) * var(--paragraph-font-ratio));
  /*Kích thước Base font-size * Tỷ lệ ^ (3 - Lead Level). Vd: Lead 2 = 13 * 1.25 ^ (3 - 2) = 16.25 */
}

.design-class h1,
.design-class h2,
.design-class h3,
.design-class h4,
.design-class h5,
.design-class h6,
.design-class .h7 {
  line-height: var(--heading-line-height);
  margin: 1.25rem 0 0.5rem 0;
  font-weight: var(--heading-font-weight);
  font-family: var(--heading-font-family);
  color: var(--heading-color-6);
}

.design-class h1,
.h1 {
  font-size: calc(var(--heading-font-size) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio));
  /*Kích thước Base font-size * Tỷ lệ ^ (6 - Heading Level). Vd: H3 = 13 * 1.200 ^ (6 - 3) = 22.46px = 1.728rem */
}

.design-class h2,
.h2 {
  font-size: calc(var(--heading-font-size) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio));
}

.design-class h3,
.h3 {
  font-size: calc(var(--heading-font-size) * var(--heading-font-ratio) * var(--heading-font-ratio) * var(--heading-font-ratio));
}

.design-class h4,
.h4 {
  font-size: calc(var(--heading-font-size) * var(--heading-font-ratio) * var(--heading-font-ratio));
}

.design-class h5,
.h5 {
  font-size: calc(var(--heading-font-size) * var(--heading-font-ratio)) !important;
}

.design-class h6,
.h6 {
  font-size: var(--heading-font-size);
}

.design-class .h7 {
  font-size: calc(var(--heading-font-size) / var(--heading-font-ratio));
}

/* Generator class */
.primary-background-color {
  background-color: var(--adm-color-primary);
}

.primary-text-color {
  color: var(--adm-color-primary);
}

.primary-border-color {
  border-color: var(--adm-color-primary);
}

.secondary-background-color {
  background-color: var(--secondary-color);
}

.secondary-text-color {
  color: var(--secondary-color);
}

.secondary-border-color {
  border-color: var(--secondary-color);
}

/* Block class */

.block-class {
  /* Block inner space */
  padding-left: var(--block-inner-space);
  padding-right: var(--block-inner-space);
  padding-top: var(--block-inner-space-top);
  padding-bottom: var(--block-inner-space-bottom);
  /* Block outside space */
  margin-left: var(--block-outside-space);
  margin-right: var(--block-outside-space);
  /* Block border radius */
  border-radius: var(--block-border-radius);
  /* Block border width */
  border-width: var(--block-border-width);
  /* Block border style */
  border-style: var(--block-border-style);
  /* Block border color */
  border-color: var(--block-border-color);
  /* Block background color */
  background-color: var(--block-background-color);
  /* Block background blur */
  backdrop-filter: blur(var(--block-background-blur));
  -webkit-backdrop-filter: blur(var(--block-background-blur));
  /* Block box shadow */
  box-shadow: var(--block-box-shadow);
}

.block-padding {
  padding-left: var(--block-inner-space) !important;
  padding-right: var(--block-inner-space) !important;
  padding-top: var(--block-inner-space-top) !important;
  padding-bottom: var(--block-inner-space-bottom) !important;
}

/* Block class */
.block-padding-tab {
  padding-left: var(--block-padding) !important;
  padding-right: var(--block-padding) !important;
}

.block-border-radius {
  border-radius: var(--block-border-radius) !important;
}

.block-border-width {
  border-width: var(--block-border-width) !important;
}

.block-border-style {
  border-style: var(--block-border-style) !important;
}

.block-border-color {
  border-color: var(--block-border-color) !important;
}

.block-background-color {
  background-color: var(--block-background-color) !important;
}

.block-tab-background-color {
  background-color: var(--block-tab-background-color) !important;
}

.block-background-blur {
  backdrop-filter: blur(var(--block-background-blur)) !important;
  -webkit-backdrop-filter: blur(var(--block-background-blur));
}

.block-box-shadow {
  box-shadow: var(--block-box-shadow) !important;
}

/* Background class*/
.background-image {
  background-image: var(--background-image);
}

.background-blur {
  filter: blur(var(--background-blur));
  -webkit-filter: blur(var(--background-blur));
}

.background-color {
  background-color: var(--background-color);
}

/* Title class */
.title-font-family {
  font-family: var(--heading-font-family);
}

.title-font-color {
  color: var(--heading-color-6);
}

.title-font-weight {
  font-weight: var(--heading-font-weight);
}

.title-font-size {
  font-size: var(--heading-font-size);
}

.title-line-height {
  line-height: var(--heading-line-height);
}

/* Paragraph class */
.paragraph-font-family {
  font-family: var(--heading-font-family);
}

.paragraph-font-color {
  color: var(--heading-color-6);
}

.paragraph-font-weight {
  font-weight: var(--heading-font-weight);
}

.paragraph-font-size {
  font-size: var(--heading-font-size);
}

.paragraph-line-height {
  line-height: var(--heading-line-height);
}

/* Button class */
.button-border-radius {
  border-radius: var(--button-border-radius) !important;
}

.button-background-color {
  background-color: var(--button-background-color) !important;
}

.button-border-width {
  border-width: var(--button-border-width) !important;
}

.button-border-color {
  border-color: var(--button-border-color) !important;
}

.button-border-style {
  border-style: var(--button-border-style) !important;
}

.button-text-color {
  color: var(--button-text-color) !important;
}

.button-box-shadow {
  box-shadow: var(--button-box-shadow) !important;
}

.small-button {
  font-size: var(--small-button-font-size) !important;
  line-height: var(--small-button-line-height) !important;
  font-weight: var(--small-button-font-weight) !important;
}

.small-button svg {
  width: 24px !important;
  height: 24px !important;
}

.middle-button {
  font-size: var(--middle-button-font-size) !important;
  line-height: var(--middle-button-line-height) !important;
  font-weight: var(--middle-button-font-weight) !important;
}

.middle-button svg {
  width: 28px !important;
  height: 28px !important;
}

.large-button {
  font-size: var(--large-button-font-size) !important;
  line-height: var(--large-button-line-height) !important;
  font-weight: var(--large-button-font-weight) !important;
}

.large-button svg {
  width: 32px !important;
  height: 32px !important;
}

.image-border-radius {
  border-radius: calc(var(--block-border-radius) / 2);
}

.pp-button {
  font-size: var(--middle-button-font-size) !important;
  line-height: var(--middle-button-line-height) !important;
  font-weight: var(--middle-button-font-weight) !important;
  padding: 7px 12px !important;
  border-radius: var(--button-border-radius) !important;
  background-color: var(--button-background-color) !important;
  border-width: var(--button-border-width) !important;
  border-color: var(--button-border-color) !important;
  border-style: var(--button-border-style) !important;
  color: var(--button-text-color) !important;
  box-shadow: var(--button-box-shadow) !important;
  gap: 8px !important;
}

.pp-button-small {
  font-size: var(--small-button-font-size) !important;
  line-height: var(--small-button-line-height) !important;
  font-weight: var(--small-button-font-weight) !important;
  padding: 3px 12px !important;
}

.pp-button-large {
  font-size: var(--large-button-font-size) !important;
  line-height: var(--large-button-line-height) !important;
  font-weight: var(--large-button-font-weight) !important;
  padding: 11px 12px !important;

}