:root{
  /* Generator heading css variables */
  --heading-color-1:#ebebebff;
  --heading-color-2:#d6d6d6ff;
  --heading-color-3:#adadadff;
  --heading-color-4:#858585ff;
  --heading-color-5:#5c5c5cff;
  --heading-color-6:#333333ff;
  --heading-color-7:#292929ff;
  --heading-color-8:#1f1f1fff;
  --heading-color-9:#141414ff;
  --heading-color-10:#0a0a0aff;
}