.selector-scroll .adm-selector {
  overflow: initial !important;
}

.selector-scroll .adm-selector .adm-space.adm-space {
  gap: 8px !important;
  flex-wrap: initial !important;
}
.selector-scroll.auto-scroll-label-product .adm-selector .adm-space.adm-space {
  gap: 12px !important;
}
