.design-class .block-tab-bar h5 {
  margin: 0;
  color: #333;
}

.design-class .tab-design-always-ghim h5 {
  margin: 0;
  color: #333;
}

.design-class .tab-design-auto-ghim h5 {
  margin: 0;
  color: #333;
}
.design-class .template_1 path, .design-class .template_2 path {
  fill: var(--heading-color-6) !important;
}
.design-class .tab-active-class.template_1 path {
  fill: var(--adm-color-primary) !important;
}
.design-class .tab-active-class.template_2 path {
  fill: var(--button-text-color) !important;
}

/* #tabMenuDesignPortal .tab-design-always-ghim .tab-active-class path {
  fill: var(--adm-color-primary) !important;
}

#tabMenuDesignPortal .tab-design-auto-ghim .tab-active-class path {
  fill: var(--adm-color-primary) !important;
} */
.template_2 .box_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--heading-color-2);
  border-radius: 100%;
}
.tab-active-class.template_2 .box_icon {
  border: 1px solid var(--button-bg-color-6);
  background-color: var(--button-bg-color-6);
}