.dndItemContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    // margin-bottom: 16px;
    overflow-x: hidden;
}

.dndContainer {
     :first-child {
        margin-left: 0px;
    }
     :last-child {
        margin-right: 0px;
    }
}