.tooltip-product-tour.introjs-tooltip{
    border-radius: 8px;
}
.tooltip-product-tour .introjs-tooltip-header {
    padding: 0;
    position: unset;
}
.tooltip-product-tour .introjs-tooltip-title {
    width: 100% ;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}
.tooltip-product-tour .introjs-skipbutton {
    color: #fff ;
}
.tooltip-product-tour .introjs-skipbutton:focus,.tooltip-product-tour .introjs-skipbutton:hover {
    color: var(--adm-color-primary)
}
.tooltip-product-tour.hidden-close-tooltip .introjs-skipbutton{
    display: none;
}
.introjs-tooltip-title .title{
    padding: 12px 12px 0;
    background-color: #fff;
    font-size: 18px;
}
.tooltip-product-tour .introjs-tooltip-title img{
    position: relative;
    z-index: 2;
}
.tooltip-product-tour .introjs-tooltiptext{
    padding: 8px 12px 12px;
    font-size: 15px;
}
.tooltip-product-tour .introjs-helperNumberLayer {
    position: absolute;
    right: 16px;
    top: 147px;
    line-height: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: #fff;
    padding: 0 8px;
    z-index: 3;
}
.tooltip-product-tour .introjs-nextbutton {
    background-color: var(--adm-color-primary);
    color: #fff;
    outline: 0;
    text-decoration: none;
    border-color: var(--adm-color-primary);
    text-shadow: none;
}
.tooltip-product-tour .introjs-nextbutton:hover, .tooltip-product-tour .introjs-button:focus {
    background-color: var(--adm-color-primary);
    color: #fff;
    outline: 0;
    text-decoration: none;
    border-color: var(--adm-color-primary);
    box-shadow: none;
    text-shadow: none;
}

.tooltip-product-tour .introjs-prevbutton, .tooltip-product-tour .introjs-prevbutton:focus, .tooltip-product-tour .introjs-prevbutton:hover {
    border-color: var(--adm-color-primary);
    background-color: #fff;
    color: var(--adm-color-primary);
    text-shadow: none;
}

.tooltip-product-tour .introjs-tooltipbuttons {
    border: none;
    padding-bottom: 12px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding-right: 4px;
}
.tooltip-product-tour .introjs-skipbutton {
    display: flex;
    align-items: center;
    left: 12px;
    top: auto;
    bottom: 12px;
    color: var(--adm-color-primary);
    font-size: 15px;
    line-height: 140%;
    width: fit-content;
    max-width: 100px;
    font-weight: 400;
    padding-right: 9px;
    overflow: hidden;
}
.introjs-helperLayer{
    box-shadow: rgb(33 33 33 / 0%) 0px 0px 1px 2px, rgb(0 0 0 / 70%) 0px 0px 0px 10000px !important;
}
.open-product-tour-popover.step-9 .adm-popover-menu-list-inner .adm-popover-menu-item:first-child {
    outline: solid #1890FF 3px;
    outline-offset: -3px;
    border-radius: 9px 9px 5px 5px;
}
.open-product-tour-popover.step-10 .adm-popover-menu-list-inner .adm-popover-menu-item:nth-child(2) {
    outline: solid #1890FF 3px;
    outline-offset: -3px;
    border-radius: 5px 5px 9px 9px;
}
.open-product-tour-popover.step-10.admin-role .adm-popover-menu-list-inner .adm-popover-menu-item:nth-child(2) {
    border-radius: 5px;
}
.tooltip-product-tour .introjs-button {
    padding: 10.5px 12px;
    font-size: 17px;
    line-height: 1.4;
}
.tooltip-product-tour.introjs-tooltip {
    width: 320px;
    max-width: 320px;
}
.tooltip-product-tour .next-label {
    display: flex;
    align-items: center;
    gap: 4px;
}
.show-label-text .text-label-intro{
    display: block;
    font-size: 17px;
}
.tooltip-product-tour .img-placeholder{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.wrapper-image{
    min-height: 179px;
    height: auto;
    position: relative;
    background-color: #f5f5f5;
}

.show-label-text .introjs-button {
    padding: 7px 12px;
}

.product-tour-custom .introjs-button {
    display: flex;
    align-items: center;
}

.tooltip-product-tour a:active {
    opacity: unset;
}

@media (max-width: 767px){
    .tooltip-product-tour.introjs-tooltip {
        max-width: 65vw;
    }
    .wrapper-image {
        min-height: 141px;
    }
    .tooltip-product-tour .introjs-helperNumberLayer{
        top: 110px;
    }
}
@media (max-width: 320px){
    .tooltip-product-tour.introjs-tooltip {
        max-width: 225px;
        width: 225px;
        min-width: 225px;
    }
    .wrapper-image {
        min-height: 125px;
    }
    .tooltip-product-tour .introjs-helperNumberLayer {
        top: 92px;
        right: 8px;
    }
}
