.affiliate-product-list-selected .adm-list-body-inner {
    max-height: 450px;
    overflow: auto;
}

.affiliate-product-list-selection .adm-checkbox-content {
    width: 100%;
    padding-left: 12px;
}

.affiliate-product-list-selection .adm-list-item-content {
    border-top: unset;
}

.affiliate-product-moving-panel-content .adm-checkbox-content {
    width: 100%;
}

.affiliate-product-moving-panel-content .adm-list-item-content {
    border-top: unset;
}

.affiliate-product-moving-panel-content .adm-list-body-inner {
    max-height: 450px;
    overflow: auto;
}

.design-class .block-affiliate-product h3 {
    margin-top: 0;
    margin-bottom: 12px;
}

.design-class .block-affiliate-product-group h3 {
    margin-top: 0;
    margin-bottom: 12px;
}

.design-class .block-affiliate-product-group h6 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.design-class .block-affiliate-product-group h4 {
    margin: 0;
}

.detect-product-link-textarea {
    overflow: auto;
    display: block;
    white-space: pre;
    overflow-wrap: normal;
    max-height: 205px;
    min-height: 40px;
    width: 100%;
    outline: none;
    font-size: 17px;
    line-height: 1.5;
}

.label-product-icon-list {
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    padding: 4px;    
}


.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.favourite::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -0.1875rem;
    border-top: 0.1875rem solid;
    border-left: 0.1875rem solid transparent;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
    color: #ea580c;
}

.favourite {
    background-color: #ea580c;
    border-radius: 2px 2px 2px 0px;
    /* padding: 0px 4px; */
    height: 16px;
    margin-bottom: 10px;
    position: relative;
    line-height: 131%;
    display: flex;
    align-items: center;

}

.favourite-label::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -0.1875rem;
    border-top: 0.1875rem solid;
    border-left: 0.1875rem solid transparent;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
    color: #ea580c;
}

.favourite-label {
    background-color: #ea580c;
    border-radius: 2px 2px 2px 0px;
    /* padding: 0px 4px; */
    height: 16px;
    margin-bottom: 10px;
    width: fit-content;
    position: relative;
    line-height: 131%;
    display: flex;
    align-items: center;

}

.sale {
    background-color: rgba(245, 158, 11, 0.85);
    border-radius: 2px;
    /* padding: 0px 4px; */
    height: 16px;
    width: fit-content;
    position: relative;
    line-height: 131%;
    display: flex;
    align-items: center;

}

.hot {
    background-color: #dc2626;
    border-radius: 2px;
    height: 16px;
}

.hot-label {
    background-color: #dc2626;
    border-radius: 2px 2px 2px 0px;
    height: 16px;
    width: fit-content;
    position: relative;
    line-height: 131%;
    display: flex;
    align-items: center;
}

.hot-label::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -0.1875rem;
    border-top: 0.1875rem solid;
    border-left: 0.1875rem solid transparent;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
    color: #dc2626;
}

.text-label {
    font-weight: 400;
    font-size: 11px;
    line-height: 131%;
    color: #FAFAFA;

}

.default-font-family {
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
}
.box-btn-aff-group{
  box-shadow: rgb(238 238 238) 0px 1px 0px inset;
  padding-top: 12px;
}
.box-btn-aff-group .btn-popup-confirm {
  box-shadow: none;
  padding-top: 8px;
}

.affiliate-product-moving-panel .adm-checkbox .adm-checkbox-icon {
  border-radius: 100% !important;
}