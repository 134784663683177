:root:root {
    /* Generator setting css variables */
    --adm-color-primary: #ee3244ff;
    --secondary-color: #0a2463ff;
    /* Block setting css variables */
    --block-border-radius: 8px;
    --block-inner-space: 12px;
    --block-inner-space-top: 12px;
    --block-inner-space-bottom: 12px;
    --block-background-color: #ffffffbf;
    --block-tab-background-color: #ffffffff;
    --block-background-color-drag-drop: #ffffffff;
    --block-background-blur: 0;
    --block-border-width: 1px;
    --block-border-color: #ffffffbf;
    --block-border-style: solid;
    --block-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --block-outside-space: 12px;
    /* Background setting css variables */
    --background-image: none;
    --background-blur: 0;
    --background-color: #f5f5f5ff;
    --background-overlay-color: #00000066;
    /* Title setting css variables */
    --heading-font-family: "roboto";
    --heading-font-color: #333333ff;
    --heading-font-size: 13px;
    --heading-font-weight: 500;
    --heading-line-height: 1.3;
    --heading-font-ratio: 1.2;
    /* Paragraph setting css variables */
    --paragraph-font-family: "roboto";
    --paragraph-font-color: #333333ff;
    --paragraph-font-size: 13px;
    --paragraph-font-weight: 400;
    --paragraph-line-height: 1.5;
    --paragraph-font-ratio: 1.2;
    /* Button setting css variables */
    --button-border-radius: 8px;
    --button-background-color: #ee3244ff;
    --button-border-width: 1px;
    --button-border-color: #ee3244ff;
    --button-border-style: solid;
    --button-text-color: #ffffffff;
    --middle-button-font-size: 17px;
    --middle-button-line-height: 1.4;
    --middle-button-font-weight: 400;
    --small-button-font-size: 15px;
    --small-button-line-height: 1.4;
    --small-button-font-weight: 400;
    --large-button-font-size: 18px;
    --large-button-line-height: 1.4;
    --large-button-font-weight: 500;
    --button-box-shadow: none;
    /* Combined block css variables */
    --combined-block-title-color: #FFFFFF;
    --combined-block-paragraph-color: #FFFFFF;
}

.design-class {
    --adm-color-primary: #ee3244ff;
    --adm-color-text: var(--text-color-6);
    /* Theo thiết lập màu chữ ở đoạn văn, tương đương --text-color-6 */
    --adm-color-text-secondary: --text-color-5;
    /* --adm-color-weak: --text-color-4; */
    /* --adm-color-light: --text-color-3; */
    --adm-font-size-main: var(--paragraph-font-size);
    /* Theo thiết lập font-size ở đoạn văn */
    --adm-font-family: var(--paragraph-font-family);
    /* Theo thiết lập font-family ở đoạn văn */
}

html,
body {
    background-color: #e6e6e6;
}


/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: unset !important;
  margin: 0 !important;
} */

@import "./theme-setting.css";
@import "./theme-config-class.css";
@import "./affiliate-product.css";
@import "./image-slider.css";
@import "./draft.css";
@import "./color-picker.css";
@import "./module/popup.css";
@import "./module/modal.css";
@import "./module/picker.css";
@import "./module/image-uploader.css";
@import "./module/custom-ant-design.css";
@import "./story.css";
@import "./image.css";
@import "./tab-menu.css";
@import "./bio.css";
@import "./spacer-and-divider.css";
@import "./module/ellipsis.css";
@import "./button-link.css";
@import "./module/selector.css";
@import "./selector-scroll.css";
@import "./generate-color/button-bg-color.css";
@import "./generate-color/button-border-color.css";
@import "./generate-color/heading-color.css";
@import "./generate-color/primary.css";
@import "./generate-color/secondary.css";
@import "./generate-color/text-color.css";
@import "./module/onboarding.css";
@import "./embed.css";
@import "./card-block.css";
@import "./image-gallery.css";
@import "./custom.css";
@import "./module/introjs.css";
@import "./module/custom-tailwind.css";
@import "./module/react-select.css";
@import "./combined-block.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.slider::-webkit-slider-thumb {
    @apply appearance-none bg-purple-400 w-4 h-4 rounded-full;
}

.dnd-copy~div {
    transform: none !important;
}

.adm-floating-panel::after {
    height: auto !important;
}

.adm-floating-panel-content {
    overflow-y: hidden !important;
}

.tab-bar-footer .adm-tabs-tab {
    padding: 10px 0 !important;
}

.adm-tabs-content {
    padding: 0 !important;
}

.adm-tabs-header {
    border-bottom: none !important;
}


/* .adm-list-item-content {
  border-top: none !important;
} */

.adm-image-uploader-cell {
    display: none !important;
}

.adm-space-item {
    margin-right: 0 !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

.layout-content .adm-tabs-tab-active path {
    fill: var(--adm-color-primary);
}

.design-content-class .adm-tabs-tab-active path {
    fill: var(--adm-color-primary) !important;
}

.design-content-class .adm-tabs-tab-active h5 {
    color: var(--adm-color-primary) !important;
}

.tab-bar-footer .adm-tabs-tab-active h1 {
    color: var(--adm-color-primary) !important;
}


.design-class .add-new-block-button-dragging-class [data-rbd-draggable-id]{
  left: auto !important;
  top: auto !important;
}

.design-class #add-new-block-button-dragging [data-rbd-draggable-id]{
    left: auto !important;
    top: auto !important;
  }


/* .block-class [data-rbd-draggable-id]{
  left: auto !important;
  top: auto !important;
} */


/* .tab-component-class [data-rbd-draggable-id] {
  left: auto !important;
  top: auto !important;
} */


/* .design-header-class > div:not([class*="tab_bar"]):first-child .adm-divider {
  display: none !important;
} */

.design-class .block-class .block-heading-class {
    margin-top: 0;
    margin-bottom: 12px;
}

.design-class .block-class .block-heading-class h3 {
    margin: 0;
}

/* .design-class .block-class .block-heading-class .block-subtitle {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
} */

.affiliate-product-list-header.is-pinned {
    background-color: white;
    color: red;
}

.max-width-content-class {
    max-width: 500px;
}

.bg-drag-drop .bg-block-drag-drop {
    background-color: var(--block-background-color-drag-drop) !important;
    backdrop-filter: blur(0px) !important;
    transition: 0.1s all ease;
}

.title-preview {
    transition: max-height .25s ease-out;
    max-height: 180px;
    overflow: hidden;
}

.title-preview.expanded {
    max-height: 650px;
}

.collapsed {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
}

.block-cursor-default {
    cursor: default !important;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 !important;
}
.swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 3px;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 3px;
  height: 3px;
  padding: 1.5px;
  border-radius: 1px;
  margin: 0;
}
.swiper-pagination .swiper-pagination-bullet-active {
  width: 13px;
  height: 3px !important;
  padding: 1.5px 6.5px;
  border-radius: 1px;
  margin: 0;
  background: var(--adm-color-primary) !important;
}
.custom-swiper-indicator .swiper-pagination{
  /* position: absolute;
  bottom: 6px;   
  left: 100% !important;
  transform: translateX(-100%) !important; */
  display: flex;
  gap: 3px;
  justify-content: end !important;
}
.disable-swiper-indicator .swiper-pagination {
  display: none !important;
}
.button-link-wrapper{
    word-break: break-word;
}