.inputCustom {
  input {
    width: 100%;
    outline: none;
    padding: 7px 12px;
    border: 1px solid #D4D4D4;
    border-radius: 3px;
    &::placeholder {
      color: #BFBFBF;
      font-weight: 400;
    }
  }
  textarea {
    width: 100%;
    outline: none;
    resize: none;
    border: 1px solid #D4D4D4;
    border-radius: 3px;
    padding: 7px 12px;
  }
}

.inputNoBorder {
  input {
    width: 100%;
    outline: none;
    padding: 0;
    border: none !important;
    &::placeholder {
      color: #BFBFBF;
      font-weight: 400;
    }
  }
  textarea {
    width: 100%;
    outline: none;
    resize: none;
    padding: 0;
    border: none !important;
  }
}
