:root{
  /* Generator primary css variables */
  --primary-1:#fdebecff;
  --primary-2:#fcd6daff;
  --primary-3:#f8adb4ff;
  --primary-4:#f5848fff;
  --primary-5:#f15b69ff;
  --primary-6:#ee3244ff;
  --primary-7:#be2836ff;
  --primary-8:#8f1e29ff;
  --primary-9:#5f141bff;
  --primary-10:#300a0eff;
}