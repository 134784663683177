.block-spacer-and-divider {
  padding: 0;
  margin-left: 0; 
  margin-right: 0;
  border-radius: 0;
  border: none;
  background-color: unset;
  backdrop-filter: unset;
  -webkit-backdrop-filter: unset;
  box-shadow: unset;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.divider-image img {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
}