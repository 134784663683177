.design-class .block-card .card-detail-layout .card-title {
  margin-top: 0px;
  margin-bottom: 4px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.design-class .block-card .card-detail-layout .card-subtitle {
  line-height: 23px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.design-class .block-card .card-detail-layout .card-label {
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 99px;
  padding: 4px 12px;
  background-color: #ffffff;
  border-color: unset;
}

.design-class .block-card .card-detail-layout .card-description {
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}

.design-class .block-card .text-below-image-center {
  text-align: center;
}

.design-class .block-card .text-below-image-center .card-text {
  color: var(--heading-color-6);
}

.design-class .block-card .text-above-image-center {
  text-align: center;
}

.design-class .block-card .text-above-image-left-2 .card-text {
  position: absolute;
  background-color: #ffffff;
  left: 16px;
  bottom: 16px;
  width: calc(100% - 32px);
  width: -moz-calc(100% - 32px);
  width: -webkit-calc(100% - 32px);
  padding: 12px;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  opacity: 80%;
}

.design-class .block-card .text-above-image-center-2 .card-text {
  position: absolute;
  background-color: #ffffff;
  left: 16px;
  bottom: 16px;
  width: calc(100% - 32px);
  width: -moz-calc(100% - 32px);
  width: -webkit-calc(100% - 32px);
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  backdrop-filter: blur(5px);
  opacity: 80%;
}

/* .design-class .card .text-above-image-left-2 .card-text .card-title{
    color: var(--heading-color-6);
}

.design-class .card .text-above-image-left-2 .card-text .card-subtitle{
    color: var(--heading-color-4);
} */

.design-class .block-card .text-right .card-text .card-title {
  color: var(--heading-color-6);
}

.design-class .block-card .swiper-pagination {
  bottom: 0px !important;
}

.design-class .block-card .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--adm-color-primary) !important;
}
