.block-button-link svg path {
  fill: var(--button-text-color);
}
.block-button-link .popover-block  path{
  fill: rgba(0, 0 ,0, 0.9);
}
.block-button-link .fill-white path {
  fill: #fff;
}
.block-button-link .fill-black path{
  fill: rgba(0, 0 ,0, 0.9);
}
