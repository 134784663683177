.full-screen-popup-body {
    background: none !important;
    height: 100%;
}

.full-screen-popup-mask {
    background: none !important;
}

.full-screen-popup {
    background: none;
}
.eco-popup .adm-popup-body {
    background: none;
}

.eco-popup .eco-picker{
    max-width: 500px;
    width: 100vh;
    background-color: white;
    margin: auto;
}

.full-screen-popup > .adm-mask {
    background-color: #e6e6e6 !important;
}

.adm-popup-body-position-bottom-panel {
    background: none !important;
}
.full-screen-popup .confirm-text-preview-apply-content .adm-mask {
    background: rgba(0, 0, 0, 0.55) !important;
}
.popup-header .title {
    padding: 12px 42px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
}

.popup-close {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 4px;
    cursor: pointer;
}

.popup-hard .popup-body{
    height: calc(100% - 110px);
}
.popup-hard.popup-hard-full .popup-body{
    height: calc(100% - 44px);
}
.popup-hard .popup-body .h-full > .adm-list{
    max-height: 100%;
}
.popup-hard .btn-popup-confirm{
    position: absolute;
    left: 0;
    bottom: 0;
}
.btn-popup-confirm{
    width: 100%;
    background-color: #fff;
    box-shadow: rgb(238 238 238) 0px 1px 0px inset;
    padding: 12px 16px;
}
.inner-popup > .adm-popup-body-position-bottom{
    max-height: 40vh;
}
.adm-popup-right .popup-close{
    left: 8px;
    right: auto;
}
.adm-popup-body-horizontal {
    width: 100%;
    background: none !important;
}
.adm-popup-body-horizontal .main-title{
    font-size: 15px;
    line-height: 20px;
}
.adm-popup-body-horizontal .sub-title{
    font-size: 12px;
    line-height: 16px;
    color: #8C8C8C;
}

.adm-popup-body-horizontal {
    height: 100%;
}

.popup-body-horizontal .popup-body {
    height: calc(100% - 44px);
}