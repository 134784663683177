:root{
  /* Generator text css variables */
  --text-color-1:#ebebebff;
  --text-color-2:#d6d6d6ff;
  --text-color-3:#adadadff;
  --text-color-4:#858585ff;
  --text-color-5:#5c5c5cff;
  --text-color-6:#333333ff;
  --text-color-7:#292929ff;
  --text-color-8:#1f1f1fff;
  --text-color-9:#141414ff;
  --text-color-10:#0a0a0aff;
}