.adm-image-uploader .adm-space.adm-space-block {
    width: 100% !important;
}
.adm-image-uploader .adm-space.adm-space-block .adm-space-item {
    width: 100% !important;
}

.adm-image-uploader .adm-space.adm-space-block .adm-space-item .adm-image-uploader-upload-button-wrap {
    width: 100% !important;
}

.adm-image-uploader-space  > .adm-space-item:last-child {
    padding-bottom: 12px !important;
}

.adm-image-uploader-space > .adm-space-item{
    padding-bottom: 0px !important; 
}