.bookingProfile{
  max-width: 500px;
  margin-top: 12px;
  margin-bottom: 12px;
  :global {
    .line-top {
      border: 1px solid #EEEEEE;
    }
    .adm-collapse-panel-content {
      .adm-list-item {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
      .adm-collapse-panel-content-inner {
        .adm-list-item-content {
          padding-right: 0;
          display: block;
        }
      }
    }
  }
}