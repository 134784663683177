
.flexbox-fix {
    /* display: none !important; */
}

.recent-color > .circle-picker > span > div > span > div{
    border: solid 1px;
    border-color: rgb(209 213 219);
} 
.chrome-picker{
    border: 1px solid #f0f0f0;
    box-shadow: none !important;
}