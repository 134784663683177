.adm-selector-item,
.adm-selector-item-multiple {
  border-color: transparent !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.adm-selector-item-active,
.adm-selector-item-multiple-active {
  border-color: red !important;
}
.primary-color {
  color: var(--adm-color-primary);
}

.adm-slider-thumb {
  position: inherit;
}

/* .selector-filter-product .adm-selector-item-active, .adm-selector-item-multiple-active {
  border-color: unset !important;
  border-width: 0px !important;
} */
.divider-design.adm-divider-left.adm-divider-horizontal::before {
  max-width: var(--block-outside-space);
}
.divider-design.adm-divider-horizontal{
  align-items: end;
  color: #1890FF;
  border-color: #1890FF;
  margin: 10px 0;
  position: relative;
}
.divider-design.adm-divider-horizontal .adm-divider-content {
  background: #1890FF;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
  font-size: 14px;
  line-height: 19px;
  padding: 2px 8px;
}
.confirm-preview-apply-content .adm-modal-body .adm-button:not(.adm-button-default){
  --background-color: transparent;
  color: var(--adm-color-primary);
}
.confirm-preview-apply-content .adm-modal-body .adm-button:not(.adm-button-default).adm-button-fill-none {
  --background-color: var(--adm-color-primary);
  color: #fff;
  padding-top: 11px;
  padding-bottom: 11px;
}
.confirm-preview-apply-content .adm-modal-body .adm-space-vertical > .adm-space-item {
  --gap-vertical: 8px !important;
}

.block-selection .adm-list-body{
  background: none;
}

.block-selection .adm-list-item-content{
  border: none;
}

.block-selection-tab-bar .adm-notice-bar-content .adm-notice-bar-content-inner{
  width: 100% !important;
}


.block-selection-tab-bar .adm-notice-bar-content-inner{
  width: 100%;
}

.adm-nav-bar-left{
  cursor: default !important;
}

.input-rounded input{
  padding: 7px 8px 7px 12px;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}
.tab-bar-footer .adm-tabs-tab-list {
  gap: 8px;
  padding: 0 4px;
}
.tab-bar-footer .adm-tabs-tab-list .adm-tabs-tab {
  width: 100%;
}
.tab-bar-footer .adm-tabs-tab-wrapper {
  padding: 0;
}
.popover-user-manual .adm-popover-inner-content{
  padding: 0;
}
.adm-image-uploader-space.adm-space{
  --gap: 12px;
}
.hidden-divider.adm-divider-horizontal::after, .hidden-divider.adm-divider-horizontal::before{
  opacity: 0.4;
}
.hidden-divider .adm-divider-content{
  background: rgba(24, 144, 255, 0.4) !important;
}
.adm-check-list-item-active {
  background-color: #FFF1F0 !important;
}
.icon-checklist {
  position: absolute;
  right: 12px;
}
.adm-check-list-item-active .icon-checklist{
  right: 32px;
}
.check-list-aff-group .adm-list-item-content-main{
  padding-right: 24px;
}
.check-list-aff-group {
  border-top: 1px solid #eee;
}
.check-list-aff-group .adm-check-list{
  max-height: calc(60vh - 44px);
  min-height: calc(30vh - 44px);
}
.divider-design-popup.adm-divider-horizontal .adm-divider-content {
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 4px 8px !important;
}