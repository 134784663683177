.content-of-combined-block {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.design-class .combined-block-design-content .block-heading-class {
    margin-top: 0;
    margin-bottom: 12px;
}

.design-class .combined-block-design-content .block-heading-class h3 {
    margin: 0;
}

.block-combined-preview-fullscreen-content .block-heading-class h3 {
    margin: 0;
}

/* .block-combined .block-advanced-setting .combined-title {
    color: var(--combined-block-title-color) !important;
}

.block-combined .block-advanced-setting .combined-subtitle {
    color: var(--combined-block-paragraph-color) !important;
} */

.block-combined-preview-fullscreen-content .block-heading-class {
    margin-top: 0;
    margin-bottom: 12px;
}