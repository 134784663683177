.adm-toast-mask .adm-toast-main-icon {
    padding: 35px 25px !important;
    word-break: normal !important;
}

.adm-item-custom {
    box-shadow: 0px 0px 0px 2px #1890FF;
    border-radius: 4px;
}

.adm-item-error {
    box-shadow: 0px 0px 0px 2px #ee3244ff;
    border-radius: 4px;
}

.adm-list-item:active:not(.adm-list-item-disabled) {
    background-color: #fff !important;
}

.adm-item-custom .adm-list-item:active:not(.adm-list-item-disabled) {
    background-color: #fff !important;
}

.adm-list-default .adm-list-body {
    border-radius: 4px;
    border: none !important;
}

.adm-item-custom-design .block-dragging,
.adm-item-custom-design .block-tabs-class {
    box-shadow: 0px 0px 0px 2px #1890FF !important;
}

.combined-block-design-content .block-dragging {
    box-shadow: none !important;
}

.adm-item-custom-design .adm-item-custom-tab {
    box-shadow: 0px 0px 0px 2px #1890FF;
}

.scroll-style-slide .adm-image {
    margin: 0 auto;
}

.d-flex {
    display: flex !important;
}

.new-block-position .adm-selector-item-description {
    font-size: 15px;
}

.selector-body svg {
    width: 16px;
    height: 16px;
}

.selector-body .adm-selector-item-active svg path {
    fill: var(--adm-color-primary);
}

.new-block-position .box-position.active {
    color: var(--adm-color-primary);
    background-color: #fff;
    border: 1px solid var(--adm-color-primary);
}

.new-block-position .box-position {
    border: 1px solid transparent;
}

.box-position.active .adm-selector-check-mark-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: solid 8px transparent;
    border-bottom: solid 8px var(--adm-color-primary);
    border-left: solid 10px transparent;
    border-right: solid 10px var(--adm-color-primary);
}

.box-position.active .adm-selector-check-mark-wrapper>svg {
    position: absolute;
    left: 0;
    top: 0;
    height: 6px;
    width: 8px;
}

.floating-new-block-position .adm-selector-item-description {
    color: var(--adm-color-text)
}

.floating-new-block-position .adm-selector-item-active .adm-selector-item-description,
.floating-new-block-position .adm-selector-item-multiple-active .adm-selector-item-description {
    color: var(--adm-color-primary)
}

.new-block-position {
    max-height: calc(100vh - 46px);
    height: 100%;
    background: #fff;
}

.floating-new-block-position {
    max-height: 100vh;
}

.selector-social-link>.adm-grid {
    --gap: 12px !important
}

.selector-social-link>.adm-grid img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}

.new-block-position .adm-selector .adm-space.adm-space {
    width: 100%;
}

.new-block-position .adm-space-horizontal.adm-space-wrap>.adm-space-item {
    width: 100%;
}

.new-block-position .adm-selector-item {
    width: 100%;
}


/* We apply these styles only from the tablet breakpoint, desktop doesn't require it */

@media (max-width: 1024px) {
    .floating-new-block-position {
        max-height: calc(var(--vh, 1vh) * 100);
    }
    .new-block-position {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 46px);
    }
}