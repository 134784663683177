.image-slider-upload {
    width: 100%;
};

.image-slider-upload  .adm-space-horizontal {
    width: 100%;
}

.image-slider-upload  .adm-space-horizontal .adm-space-item {
    width: 100%;
}

.image-uploaded-hidden .adm-image-uploader-space .adm-space-item:first-child {
    display: none !important;
    padding-bottom: 0 !important;
}