.title-header-popup {
    color: var(--body-text-color, #333);
    text-align: center;
    /* Components/Cascader/Title */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
}

.list-item-popup {
    color: var(--body-text-color, #333);
    /* Components/List/Content main */
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 25.5px */
}