.uploadProductFile {
  :global {
    .adm-center-popup-wrap {
      width: 151px !important;
      height: 118px;
      min-width: 151px !important;
    }

    .adm-modal-body:not(.adm-modal-with-image) {
      padding-top: 0;
    }

    .adm-modal-content {
      padding: 0;
    }
    .adm-modal-footer-empty {
      height: 0;
    }
  }
}