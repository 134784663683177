.text-content ol {
  list-style: decimal;
  padding-inline-start: 12px;
}

.text-content ul {
  list-style: inside;
  /* padding-left: 12px; */
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-colorpicker-modal {
  left: -50px !important;
}

.editor-class {
  min-height: 210px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.editor-class .DraftEditor-root {
  min-height: 200px !important;
}

.editor-class .DraftEditor-root .DraftEditor-editorContainer {
  min-height: 200px !important;
}

.editor-class .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
  min-height: 200px !important;
}

.editor-class h1,
.editor-class h2,
.editor-class h3,
.editor-class h4,
.editor-class h5,
.editor-class h6,
.editor-class .h7 {
  line-height: var(--heading-line-height);
  margin: 1.25rem 0 0.5rem 0;
  font-weight: var(--heading-font-weight);
  font-family: var(--heading-font-family);
  color: unset;
}

.editor-class h1 {
  font-size: calc(
    var(--heading-font-size) * var(--heading-font-ratio) *
      var(--heading-font-ratio) * var(--heading-font-ratio) *
      var(--heading-font-ratio) * var(--heading-font-ratio)
  ); /*Kích thước Base font-size * Tỷ lệ ^ (6 - Heading Level). Vd: H3 = 13 * 1.200 ^ (6 - 3) = 22.46px = 1.728rem */
}

.editor-class h2 {
  font-size: calc(
    var(--heading-font-size) * var(--heading-font-ratio) *
      var(--heading-font-ratio) * var(--heading-font-ratio) *
      var(--heading-font-ratio)
  );
}
.editor-class h3 {
  font-size: calc(
    var(--heading-font-size) * var(--heading-font-ratio) *
      var(--heading-font-ratio) * var(--heading-font-ratio)
  );
}
.editor-class h4 {
  font-size: calc(
    var(--heading-font-size) * var(--heading-font-ratio) *
      var(--heading-font-ratio)
  );
}
.editor-class h5 {
  font-size: calc(
    var(--heading-font-size) * var(--heading-font-ratio)
  ) !important;
}
.editor-class h6 {
  font-size: var(--heading-font-size);
}

.editor-class .h7 {
  font-size: calc(var(--heading-font-size) / var(--heading-font-ratio));
}
