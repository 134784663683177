.theme-setting-class .adm-list-body{
    background-color: #F5F5F5 !important;
}

.theme-setting-class .adm-list-body .adm-list-body-inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.item-preview-text{
    padding-bottom: 12px;
}
.preview-text-block .table{
    border-collapse: collapse; 
}
.scale-preview-text{
    transition: font-size 0.6s ease 0s;
}
button.btn-big-preview{
    padding-bottom: 12px ;
    padding-top: 12px ;
}
button.btn-big-preview{ 
    padding-bottom: 7px ;
    padding-top: 7px ;
}
button.btn-small-preview{
    padding-bottom: 6px ;
    padding-top: 6px ;
}
.preview-button-block{
    border-bottom: 1px solid #eee
}
.preview-button-block .flex{
    display: flex;
}

.content-block-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.outside-space{
    margin-left: var(--block-outside-space);
    margin-right: var(--block-outside-space);
}

.inside-space{
   padding-left: var(--block-inner-space); 
   padding-right: var(--block-inner-space); 
}