.tabContent{
  margin-top: 12px;
  border-top: 1px solid #EEEEEE;
  padding: 12px 12px 0px;
  :global{
    .adm-tab-bar-wrap{
      gap: 12px;
      .adm-tab-bar-item{
        --adm-font-size-3: 15px;
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        padding: 8px 16px;
        background: #F5F5F5;
        color: black;
      }
      .adm-tab-bar-item-active{
        background: #FFFFFF;
        border: 1px solid #EE3244;
        border-radius: 8px;
        color: #EE3244;
      }
    }
  }
}