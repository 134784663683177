.swiper-onboarding{
    height: 100% !important
}
.swiper-onboarding.adm-swiper-horizontal .adm-swiper-indicator {
    bottom: 50px;
    background-color: #fff;
    height: 50px;
    width: 100%;
    text-align: center;
    padding-top: 25px;
}
.swiper-onboarding.adm-swiper-horizontal .adm-swiper-indicator .adm-page-indicator-horizontal {
    justify-content: center;
}
.selector-category-onboarding.adm-selector .adm-space.adm-space{
    column-gap: 12px
}
.selector-category-onboarding .adm-selector-item {
    border-color: transparent !important;
    border-width: 1px !important;
}
.selector-category-onboarding .adm-selector-item-active,.selector-category-onboarding .adm-selector-item-multiple-active{
    border-color: var(--checked-text-color) !important;
}