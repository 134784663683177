.adm-picker-popup .adm-picker-header{
    height: 44px;
}
.adm-picker-header .adm-picker-header-button:nth-child(1){
    position: absolute;
    right: 8px;
    top: 4px;
}
.adm-picker-header .adm-picker-header-button:nth-child(3){
    position: absolute;
    bottom: 12px;
    left: 16px;
    width: calc(100% - 32px);
    background: var(--adm-color-primary);
    color: var(--adm-color-white);
    padding: 7px 12px;
    text-align: center;
    border-radius: 4px;
    z-index: 1;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
}
.adm-picker.eco-picker, .adm-picker-popup .adm-picker{
    overflow: visible;
}
.adm-picker-popup .adm-picker-body {
    padding-bottom: 65px;
}