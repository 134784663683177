.adm-center-popup-wrap {
    width: 280px !important;
}

.modal-error-class .adm-modal-footer {
    border-top: 1px solid #EEEEEE;
}

.theme-template-selection-modal .adm-modal-footer.adm-space {
    --gap-vertical: 8px !important;
}

.modal-error-class .adm-modal-footer {
    border-top: 1px solid #EEEEEE;
}
.modal-product-tour .adm-image-tip {
    height: 158px;
}
.modal-default .adm-modal-footer .adm-modal-button {
    padding-top: 7px;
    padding-bottom: 7px;
}