.design-class .block-bio h4 {
  margin: 0 !important;
}
.block-bio ul, .block-text ul {
  display: block;
  list-style: disc;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.block-bio ol, .block-text ol {
  display: block;
  list-style: decimal;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}   

.bio-collapse{
  max-height: 80px;
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}


