.container-modal-delete-forever{
    :global {
        .adm-modal-header {
            padding: 0px !important;
        }
        .adm-modal-body:not(.adm-modal-with-image) {
            padding-top: 0px;
        }
    }

    .button-confirm-delete-forever {

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    
        .text-confirm {
            color: var(--theme-white, #FFF);
            text-align: center;
            /* Components/Button/Default/Title */
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 23.8px */
        }
    }
    
    .button-cancel-delete-forever {
        display: flex;
        padding: 7px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    
        .text-cancel {
            color: var(--theme-primary-1, #EE3244);
            text-align: center;
            /* Components/Button/Default/Title */
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 23.8px */
        }
    }
    
    .title-modal-delete-forever {
        color: var(--body-text-color, #333);
        text-align: center;
        /* Components/Modal/Title */
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 138.889% */
    }
    
    .content-modal-delete-forever {
        color: var(--body-text-color, #333);
        text-align: center;
        /* Components/Modal/Content */
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 21px */
        margin-top: 8px;
    }
}