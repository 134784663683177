.react-select__value-container {
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.react-select__menu {
    background-color: unset !important;
    padding-bottom: 60px;
    box-shadow: none !important;
}

.react-select__menu-list {
    background-color: #fff;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
}

.react-select__input::placeholder,
.react-select__placeholder {
    color: #BFBFBF !important;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
}

.react-select__multi-value__label {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 150%;
}

.react-select__multi-value {
    border-radius: 4px !important;
}

.react-select__indicators {
    cursor: pointer;
}

.react-select__placeholder{
    margin-left: 0 !important;
}

.react-select__value-container .react-select__multi-value:first-child {
    margin-left: 0 !important;
}