:root{
  /* Generator button-border css variables */
  --button-border-color-1:#fdebecff;
  --button-border-color-2:#fac2c7ff;
  --button-border-color-3:#f799a2ff;
  --button-border-color-4:#f3707cff;
  --button-border-color-5:#f15b69ff;
  --button-border-color-6:#ee3244ff;
  --button-border-color-7:#be2836ff;
  --button-border-color-8:#8f1e29ff;
  --button-border-color-9:#5f141bff;
  --button-border-color-10:#300a0eff;
}