:root{
  /* Generator secondary css variables */
  --secondary-1:#e7e9efff;
  --secondary-2:#ced3e0ff;
  --secondary-3:#9da7c1ff;
  --secondary-4:#6c7ca1ff;
  --secondary-5:#3b5082ff;
  --secondary-6:#0a2463ff;
  --secondary-7:#081d4fff;
  --secondary-8:#06163bff;
  --secondary-9:#040e28ff;
  --secondary-10:#020714ff;
}